<template>
  <div class="show">
    <PageTop :addShow="false" :sloganShow="true">
      <div class="adTitle" slot="adTitle">农场秀</div>
    </PageTop>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTop: () => import("@/components/PageTop.vue"),
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.show {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 48px 0;
  .land_admin {
    height: 107px;
  }
}
</style>
